<template>
  <div>
    <div class="col secondary-footer" style="">
      <div class="container">
        <div class="row" style="padding: 20px">
          <h5><strong>About Hireclap</strong></h5>
          <p>Hireclap is the largest video resume based job site in India. More than 3 million+ jobseekers registered with Hireclap, over 5000 companies used our recruitment services to hire candidates for its entry-level / fresher hiring and the database is growing daily.</p>
          <p>Job seekers can upload their 2-minute video resume -Visume, to showcase who they are and why a company should hire them. A video profile is an opportunity to describe yourself to a company beyond your resume.</p>
          <p>Companies can post free jobs and shortlist best matching candidates from video profiles. Recruiters can use the latest recruitment tools, reach out to the largest pool of active job seekers and make the data driven hiring decisions virtually.</p>
        </div>
        <div class="card-deck">
          <div class="card" style="background: transparent;border: none;">
            <div class="card-body">
              <h5 class="card-title"><strong>Need help?</strong></h5>
              <a href="mailto:recruit@hireclap.com"><h6>recruit@hireclap.com</h6></a>
              <h6>+91 9037 156 256</h6>
              <!-- <div class="btn footerBtn mt-7">Contact  Us</div> -->
              <div class="btn mt-7"><a href="/contact-us" class="footerBtn btn px-2" target="_blank">Contact  Us</a></div>

              <!-- <a class="btn footerBtn mt-7" href="/contact-us">Contact  Us</a> -->

            <div class="row gap20"></div>                        
          </div>
        </div>
        <div class="card" style="background: transparent;border: none;">
          <div class="card-body">
            <h5 class="card-title"><strong>Candidate zone</strong></h5>
            <p class="card-text">
              <a class="flink" href="/jobseeker/register" target="_blank">Post visume</a>
              <a class="flink" href="/" target="_blank">Candidate services</a>
            </p>
          </div>
        </div>
        <div class="card" style="background: transparent;border: none;">
          <div class="card-body">
            <h5 class="card-title"><strong>Employer zone</strong> </h5>
            <p class="card-text">
              <a class="flink" href="/recruit/post-job" target="noopener">Free job posting</a>
              <a class="flink" target="_blank" href="/">Recruitment package</a>
              <a class="flink" target="_blank" href="/">Resume search</a>
              <a class="flink" target="_blank" href="/">End to end consulting</a>
              <a class="flink" target="_blank" href="https://www.assessengine.com/">Online assessment <span><v-icon style="color:#007bff" size="14px">{{$icons.starCircle}}</v-icon></span></a>
              <a class="flink" target="_blank" href="/">Application management system</a>
              <a class="flink" target="_blank" href="/recruit/package-pricing">Pricing</a>
              <a class="flink" target="_blank" href="/skillhire">Skillhire</a>
            </p>
          </div>
        </div>
        <div class="card" style="background: transparent;border: none;">
          <div class="card-body">
            <h5 class="card-title"><strong>Institute zone</strong></h5>
            <p class="card-text">
              <a class="flink" target="_blank" href="/institutes">List your institute</a>
              <!-- <a class="flink" target="_blank" href="/training-institutes">Institute list</a> -->
              <!-- <a class="flink" target="_blank" href="/training-courses">Course list</a> -->
              <a class="flink" target="_blank" href="/">Virtual campus placement</a>
              <a class="flink" target="_blank" href="/">Virtual placement cell (VPCell)</a>
            </p>
          </div>
        </div>
        <div class="card" style="background: transparent;border: none;">
          <div class="card-body">
            <h5 class="card-title"><strong>College zone</strong></h5>
            <p class="card-text">
              <a class="flink" target="_blank" href="/colleges/register">List your college</a>
              <!-- <a class="flink" target="_blank" href="/view-colleges">College list</a> -->
              <a class="flink" target="_blank" href="/">Campus placements </a>
              <a class="flink" target="_blank" href="/">Placement preparation </a>
              <a class="flink" target="_blank" href="/">Mock assessments </a>
            </p>
          </div>
        </div>
      </div>
    </div> 
    </div>

    <div class="col site-links secondary-footer">
      <div class="container">
        <div class="card-deck">
         <div class="card">
          <div class="card-body">
            <a href="/jobs-by-courses" target="noopener" style="color:#333 !important;"><h5 class="card-title"><strong>Jobs by courses</strong></h5></a>
            <p class="card-text">
              <!-- <span v-for="job in jobs_by_courses" :key="job.id"><a class="flink" :href="job.search_url" target="noopener">{{job.name}}</a><br></span> -->
              <a class="flink" href="/be-b-tech-jobs" target="noopener">BE/B.Tech jobs</a>
              <a class="flink" href="/mba-pgdm-jobs" target="noopener">MBA/PGDMS jobs</a>
              <a class="flink" href="/mca-jobs" target="noopener">MCA jobs</a>
              <a class="flink" href="/me-m-tech-jobs" target="noopener">ME/M.Tech jobs</a>
              <a class="flink" href="/msc-jobs" target="noopener">MSc jobs</a>
              <a class="flink" href="/bca-jobs" target="noopener">BCA jobs</a>
              <a class="flink" href="/bsc-jobs" target="noopener">BSc jobs</a>
              <a class="flink" href="/diploma-jobs" target="noopener">Diploma jobs</a>
            </p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <a href="/jobs-by-cities" target="noopener" style="color:#333 !important;"><h5 class="card-title"><strong>Jobs by cities</strong></h5></a>
            <p class="card-text">
              <!-- <span v-for="job in jobs_by_cities" :key="job.id"><a class="flink" :href="job.search_url" target="noopener">{{job.name}}</a><br></span> -->
              <a class="flink" href="/jobs-in-ahmedabad" target="noopener">Jobs in Ahamedabad</a>
              <a class="flink" href="/jobs-in-bangalore" target="noopener">Jobs in Bangalore</a>
              <a class="flink" href="/jobs-in-chennai" target="noopener">Jobs in Chennai</a>
              <a class="flink" href="/jobs-in-delhi" target="noopener">Jobs in Delhi</a>
              <a class="flink" href="/jobs-in-hyderabad" target="noopener">Jobs in Hyderabad</a>
              <a class="flink" href="/jobs-in-kolkata" target="noopener">Jobs in Kolkata</a>
              <a class="flink" href="/jobs-in-kochi" target="noopener">Jobs in Kochi</a>
              <a class="flink" href="/jobs-in-mumbai" target="noopener">Jobs in Mumbai</a>
              <a class="flink" href="/jobs-in-pune" target="noopener">Jobs in Pune</a>
              <a class="flink" href="/jobs-in-gurgaon" target="noopener">Jobs in Guragaon</a>
              <a class="flink" href="/jobs-in-noida" target="noopener">Jobs in Noida</a>
            </p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <a href="/jobs-by-categories" target="noopener" style="color:#333 !important;"><h5 class="card-title"><strong>Jobs by category</strong> </h5></a>
            <p class="card-text">
              <!-- <span v-for="job in jobs_by_category" :key="job.id"><a class="flink" :href="job.search_url" target="noopener">{{job.name}}</a><br></span> -->
              <a class="flink" href="/govt-jobs-jobs-vacancies" target="noopener">Government Jobs</a>
              <a class="flink" href="/it-software-jobs-vacancies" target="noopener">IT / Software Jobs</a>
              <a class="flink" href="/bank-jobs-vacancies" target="noopener">Bank Jobs</a>
              <a class="flink" href="/defence-jobs-vacancies" target="noopener">Defence Jobs</a>
              <a class="flink" href="/apprenticeship-jobs-vacancies" target="noopener">Apprenticeship Jobs</a>
              <a class="flink" href="/health-care-jobs-vacancies" target="noopener">Healthcare Jobs</a>
              <a class="flink" href="/core-technical-jobs-vacancies" target="noopener">Core Technical Jobs</a>
            </p>
          </div>
        </div>
      </div>
         <div class="card-deck">
           <div class="card">
          <div class="card-body">
            <a href="/jobs-by-roles" target="noopener" style="color:#333 !important;"><h5 class="card-title"><strong>Jobs by roles</strong></h5></a>
            <p class="card-text">
              <a class="flink" href="/accountant-jobs-vacancies" target="noopener">Accountant Jobs</a>
              <a class="flink" href="/architect-jobs-vacancies" target="noopener">Architect Jobs</a>
              <a class="flink" href="/content-writer--jobs-vacancies" target="noopener">Content Writer Jobs</a>
              <a class="flink" href="/data-entry-back-office-jobs-vacancies" target="noopener">Data Entry /Back Office Jobs</a>
              <a class="flink" href="/fashion-designer-jobs-vacancies" target="noopener">Fashion Designer Jobs</a>
              <a class="flink" href="/hr-admin-jobs-vacancies" target="noopener">HR / Admin Jobs</a>
              <a class="flink" href="/management-trainee-jobs-vacancies" target="noopener">Management Trainee Jobs</a>
              <a class="flink" href="/pharmacist-medical-rep-jobs-vacancies" target="noopener">Pharmacist / Medical Rep Jobs</a>
              <a class="flink" href="/sales-marketing-executive-jobs-vacancies" target="noopener">Sales/Marketing Executive Jobs</a>
              <a class="flink" href="/receptionist-front-office-jobs-vacancies" target="noopener">Receptionist/Front Office Jobs</a>
              <a class="flink" href="/seo-social-media-jobs-vacancies" target="noopener">SEO / Social Media Jobs</a>
            </p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <a href="/jobs-by-branches" target="noopener" style="color:#333 !important;"><h5 class="card-title"><strong>Jobs by branches</strong></h5></a>
            <p class="card-text">
              <a class="flink" href="/be-b-tech-aeronautical-avionics-aerospace-engineering-jobs" target="noopener">Aeronautical Engineering Jobs</a>
              <a class="flink" href="/be-b-tech-architecture-engineering-jobs" target="noopener">Architecture Engineering Jobs</a>
              <a class="flink" href="/be-b-tech-automobile-engineering-jobs" target="noopener">Automobile Engineering Jobs</a>
              <a class="flink" href="/be-b-tech-computer-science-engineering-cse-jobs" target="noopener">Computer Science Engineering Jobs</a>
              <a class="flink" href="/be-b-tech-chemical-engineering-jobs" target="noopener">Chemical Engineering Jobs</a>
              <a class="flink" href="/be-b-tech-civil-engineering-jobs" target="noopener">Civil Engineering Jobs</a>
              <a class="flink" href="/be-b-tech-electronics-communication-engineeringece-jobs" target="noopener">ECE Jobs</a>
              <a class="flink" href="/be-b-tech-electrical-engineering-jobs" target="noopener">Electrical Engineering Jobs</a>
              <a class="flink" href="/be-b-tech-electronics-instrumentation-engineering-jobs" target="noopener">Electronics & Instrumentation Engineering Jobs</a>
              <a class="flink" href="/be-b-tech-mechanical-engineering-jobs" target="noopener">Mechanical Engineering Jobs</a>
              <a class="flink" href="/bsc-nursing-jobs" target="noopener">Nursing Jobs</a>
              <a class="flink" href="/bsc-physics-applied-physics-jobs" target="noopener">BSC Physics Jobs</a>
            </p>
          </div>
        </div>        
      </div>
      </div>
    </div>
    <div class="footer">
      <div class="container p-3">
        <div class="row">
          <div class="col-lg-5 col-md-12  alignmob">
            <p class="content-center mb-0" v-if="this.year">© {{year}} Talentmap Technologies Pvt Ltd</p>

            <!-- <p class="content-center mb-0" v-if="this.year">© {{year}} <a href="/" style="color:#ffff">Hireclap</a> | Hire best matching candidates</p> -->
          </div>
          <div class="col-lg-4 col-md-6  alignmob">
            <p class="content-center mb-0"><small class="mb-0"><a href="/privacy" target="noopener" class="flink text-decoration-none">Privacy Policy </a>|<a href="/terms" target="noopener" class="flink text-decoration-none"> Terms &amp; Conditions</a></small></p>
          </div>
          <div class="col-lg-3 col-md-6 alignmob">
            <a href="https://www.facebook.com/Hireclap" aria-label="facebook" target="noopener">
              <v-icon class="mdi-footer">{{ $icons.facebook }}</v-icon>
            </a>
            <a href="https://twitter.com/hireclap" aria-label="twitter" target="noopener">
              <v-icon class="mdi-footer">{{ $icons.twitter}}</v-icon>
            </a>
            <a href="https://www.linkedin.com/company/hireclap" aria-label="linkedin" target="noopener">
              <v-icon class="mdi-footer">{{ $icons.linkedin}}</v-icon>
            </a>
            <a href="https://www.youtube.com/channel/UC_Rhjfe3TniDTGLn8nxcu0Q" aria-label="youtube" target="noopener">
              <v-icon class="mdi-footer">{{ $icons.youtube}}</v-icon>
            </a>
            <a href="https://www.instagram.com/hireclap" aria-label="instagram" target="noopener">
              <v-icon class="mdi-footer">{{ $icons.instagram}}</v-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Actions from '../libraries/apiActions.js';
import '../assets/hire.css';
export default {
  name: "App-footer",
  data(){
    return{
      action: new Actions(),
      year:null
    }
  },
  beforeMount(){
    this.year = new Date().getFullYear();
  }
}
</script>
<style scoped>
.footerBtn {border: 1px solid #0054ad;color: #0054ad;}.footerBtn:hover{color: #212529}
</style>